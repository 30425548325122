<template>
  <div
    id="top"
    class="home-container text-light px-2 px-lg-5"
    v-bind:class="{
      'header-margin': !isAuthenticated(),
    }"
  >

    <b-container fluid class="px-lg-4 px-xl-5 pt-5">
      <b-row class="text-center pt-4 pt-md-5">
        <b-col cols="12" class="pt-md-5 mt-md-5">
          <img src="../../assets/trackd-icon-white.svg" class="trackd-icon mt-md-5" />
          <h1 class="heading mt-3 mb-3 pb-1">Your Music, Your Rights</h1>
        </b-col>
      </b-row>
    </b-container>

    <b-container class="px-sm-4 px-md-5 px-lg-4 px-xl-5 pt-3">
      <div class="pt-md-5">
        <b-row class="pb-3">
          <b-col cols="12" class="pb-2 pb-md-5">

            <h2 class="section-heading text-center mb-0">In a Nutshell</h2>

            <p class="text-center section-desc">
              We provide Trackd as a platform for Artists to Create and a safe and secure environment for Fans <br class="d-none d-block-md"> to Discover and enjoy amazing content.
            </p>

            <p class="text-center section-desc  pt-3 mb-0 font-weight-bold"><b>Your Responibility</b></p>
            <p class="text-center section-desc">
              As Artists ourselves, we expect all users to respect other people’s copyright. You are responsible for <br class="d-none d-block-md"> ensuring that the tracks recorded, uploaded and shared via Trackd do not infringe any third party <br class="d-none d-block-md"> copyright or other intellectual property right and you have the right to use the content. We have <br class="d-none d-block-md"> technology in place to enforce rights management and will not condone breaches.
            </p>

            <p class="text-center section-desc pt-3">
              As an Artist you are responsible for any revenue share with co-writers, labels and managers as per <br class="d-none d-block-md"> the agreements you may have in place with these people/parties. Within your profile you may add <br class="d-none d-block-md"> details of any rights organisations for your own reporting purposes.
            </p>

            <p class="text-center section-desc pt-3">
              To be totally clear; we own no rights in your work or that which you may share, whatsoever.
            </p>

            <p class="text-center section-desc pt-3 mb-0 font-weight-bold"><b>Our Interest</b></p>
            <p class="text-center section-desc">
              We do not monetize the content. Any fees you may pay for Trackd are to provide the platform and <br class="d-none d-block-md"> offer you; the Artist, an alternative and viable way for you to make money from your content.
            </p>

            <p class="text-center section-desc  pt-3 mb-0 font-weight-bold"><b>Exclusivity</b></p>
            <p class="text-center section-desc">
              We are not exclusive. You may monetize your work alongside streaming platforms, but it is our <br class="d-none d-block-md"> hope you will see the benefits of Trackd and we will become your preferred platform and provide <br class="d-none d-block-md"> you with a reliable source of income.
            </p>

            <p class="text-center section-desc pt-3">
              View our Full Terms of use and Guidelines <router-link :to="{name: 'Legal'}" class="text-light text-underline">here</router-link>.
            </p>

          </b-col>

        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "AboutUs",

  methods: {
    ...mapGetters(["isAuthenticated"]),
    app_store_click() {
      window.open(
        "https://apps.apple.com/gb/app/trackd-songs-start-here/id1496465739",
        "_blank"
      );
    },
  },
};
</script>

<style scoped>

.section-heading {
  font-weight: 300;
  font-size: 40px;
  line-height: 90px;

}

.c-header-buttons-container {
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 1000;
  width: 98%;
}

.c-btn-hamburger-icon,
.c-btn-back-icon {
  padding-left: 0px;
  padding-top: 15px;
}

.c-img-hamburger-icon,
.c-img-back-icon {
  width: 52px;
  height: 52px;
}

.home-container {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)),
    url("../../assets/music_rights_bg.jpg");
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  /* Center and scale the image nicely */
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
}

.header-margin {
  margin-top: 100px;
}

.trident {
  font-size: 14px;
  font-weight: 900;
  line-height: 30px;
}

.heading {
  font-size: 34px;
  font-weight: 300;
  line-height: 36px;
}

.section-heading {
  font-size: 24px;
  font-weight: 300;
  line-height: 90px;
}

.summer68 {
  height: 200px;
}

.roots {
  height: 200px;
}

.section-desc {
  font-size: 18px;
  font-weight: 300;
  line-height: 24px;
}

.normal-text {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

.list-heading {
  font-size: 12px;
  font-weight: 400;
  line-height: 0px;
}

#feature-list li {
  font-size: 11px;
  font-weight: 300;
  line-height: 16px;
}

.section-border-right {
  margin-right: -20px;
  margin-left: 24%;
}

.section-border-left {
  margin-left: -10%;
  width: 90%;
}

.pic-caption {
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
}

@media (min-width: 576px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

  .heading {
    font-size: 48px;
    font-weight: 200;
    line-height: 90px;
  }

  .section-heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 90px;
  }

  .summer68 {
    height: 275px;
  }

  .roots {
    height: 250px;
  }

  .section-desc {
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 12px;
    font-weight: 400;
    line-height: 0px;
  }
  .section-border-right {
    margin-right: -5%;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 768px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

.heading {
  font-size: 48px;
  font-weight: 300;
  line-height: 36px;
}

  .section-heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 90px;
  }

  .normal-text {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .app-store {
    height: 50px;
  }

  .summer68 {
    height: 250px;
  }

  .roots {
    height: 215px;
  }

  .section-desc {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 14px;
    font-weight: 400;
    line-height: 0px;
  }

  .section-border-right {
    margin-right: -55px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 992px) {
  .trident {
    font-size: 14px;
    font-weight: 900;
    line-height: 30px;
  }

.heading {
  font-size: 48px;
  font-weight: 300;
  line-height: 36px;
}

  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .normal-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 24px;
  }

  .summer68 {
    height: 310px;
  }

  .roots {
    height: 270px;
  }

  .section-desc {
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .list-heading {
    font-size: 16px;
    font-weight: 400;
    line-height: 8px;
  }

  .section-border-right {
    margin-right: -70px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}

@media (min-width: 1200px) {
  .section-heading {
    font-size: 40px;
    font-weight: 300;
    line-height: 90px;
  }

  .summer68 {
    height: 416px;
  }

  .roots {
    height: 300px;
  }

  .section-desc {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .normal-text {
    font-size: 18px;
    font-weight: 300;
    line-height: 24px;
  }

  .list-heading {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .section-border-right {
    margin-right: -94px;
    margin-left: 24%;
  }

  .section-border-left {
    margin-left: -10%;
    width: 90%;
  }
}


@media (max-width: 414px) { 
  .home-container {
    background-size: 135%;
  }
}

@media (max-width: 375px) { 

  .home-container {
    background-size: 135%;
  }

  .heading {
    font-size: 30px;
    font-weight: 300;
    line-height: 36px;
  }

}
@media (max-width: 320px) { 

  .roots {
    margin-left: 3px !important;
    width: 100%;
  }

  .heading {
    font-size: 24px;
    font-weight: 300;
    line-height: 36px;
  }

}
</style>
